.about-content {
  .detail,
  .contact {
    padding-top: $rhythm * 2;
    padding-bottom: $rhythm * 2;
    > *:not(.image) {
      max-width: 25em;
    }
    a {
      @include hover-opacity(.4);
    }
  }
  .detail {
    @include scalable-font(24, 13, 1.3, null, 24);
    .image {
      margin-bottom: 1em;
    }
  }
  .contact {
    @include scalable-font(22, 12, 1.45, null, 22);
  }
  @include phone {
    // @.about-content
    .detail,
    .contact {
      padding-bottom: $rhythm * 2;
      > *:not(.images) {
        width: 90%;
      }
    }
    .contact {
      padding-bottom: $rhythm * 5;
    }
  }
  @include tablet-plus {
    // @.about-content
    .inside {
      display: flex;
      flex-direction: row;
    }
    .detail,
    .contact {
      min-height: 100vh;
      @include cols(12);
      padding-right: $rhythm;
    }
    .detail {
      .images {
        width: 90%;
      }
    }
    .contact {
      padding-left: tablet-plus-inset();
    }
  }
}
