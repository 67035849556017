@mixin webfont($name, $filename, $weight, $style: normal) {
  // see https://css-tricks.com/snippets/css/using-font-face/
  // use like so...
  // @include webfont(whatyouwanttocallit-web, 'filename', weightnumber, italic);
  @font-face {
    font-family: '#{$name}';
    src: url('../f/' + $filename + '.eot'); // IE9
    src: url('../f/' + $filename + '.eot?#iefix') format('embedded-opentype'), // IE6-IE8
         url('../f/' + $filename + '.woff') format('woff'); //, // proper browsers
        //  url('../f/helvetica/' + $filename + '.woff2') format('woff');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin webfontwoff2($name, $filename, $weight, $style: normal) {
  @font-face {
    font-family: '#{$name}';
    src: url('../f/' + $filename + '.eot'); // IE9
    src: url('../f/' + $filename + '.eot?#iefix') format('embedded-opentype'), // IE6-IE8
         url('../f/' + $filename + '.woff') format('woff'), // proper browsers
         url('../f/' + $filename + '.woff2') format('woff2'); // super mod!
    font-weight: $weight;
    font-style: $style;
  }
}

// Sohne Web - By KLIM
// @include webfontwoff2(sohne-web, 'SohneWeb-Medium', 500);
// @include webfontwoff2(sohne-web, 'SohneWeb-Regular', 400);
// NO ITALIC @include webfont(sohne-web, 'SohneWeb-Regular', 400, italic);


// Heldane
// @include webfontwoff2(heldane-web, 'HeldaneTextWeb-Regular', 400);
// @include webfontwoff2(heldane-web, 'HeldaneTextWeb-RegularItalic', 400, italic);
// @include webfontwoff2(heldane-web, 'HeldaneTextWeb-Medium', 500);
// @include webfontwoff2(heldane-web, 'HeldaneTextWeb-MediumItalic', 500, italic);

// fonts.com
@import url('https://fast.fonts.net/t/1.css?apiType=css&projectid=bd0a5fee-0ce1-42be-bd25-f8f508977ac9');
    @font-face{
        font-family: 'helvetica-neue-web';
        font-weight: 400;
        src: url('../f/helvetica/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix');
        src: url('../f/helvetica/522972a6-5867-47ae-82fc-a7cf9bd5d4d4.eot?#iefix')
              format('eot'),
            url('../f/helvetica/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2')
              format('woff2'),
            url('../f/helvetica/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff')
              format('woff'),
            url('../f/helvetica/8f4a1705-214a-4dd0-80b8-72252c37e688.ttf')
              format('truetype');
    }
    @font-face{
        font-family: 'helvetica-neue-web';
        font-weight: 400;
        font-style: italic;
        src: url('../f/helvetica/c708845d-fe72-4432-9276-47c4f91aec1c.eot?#iefix');
        src: url('../f/helvetica/c708845d-fe72-4432-9276-47c4f91aec1c.eot?#iefix')
              format('eot'),
            url('../f/helvetica/4bd56f95-e7ab-4a32-91fd-b8704cbd38bc.woff2')
              format('woff2'),
            url('../f/helvetica/4fe1c328-1f21-434a-8f0d-5e0cf6c70dfb.woff')
              format('woff'),
            url('../f/helvetica/c07fd9e2-5e0b-4297-9229-af0c8e331d94.ttf')
              format('truetype');
    }
