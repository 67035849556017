h1 a, h2 a, h3 a, h4 a, h5 a, h6 a,
h1 a:link, h2 a:link, h3 a:link, h4 a:link, h5 a:link, h6 a:link,
h1 a:visited, h2 a:visited, h3 a:visited, h4 a:visited, h5 a:visited, h6 a:visited,
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover,
h1 a:active, h2 a:active, h3 a:active, h4 a:active, h5 a:active, h6 a:active,
h1 a.current, h2 a.current, h3 a.current, h4 a.current, h5 a.current, h6 a.current,
h1 a.current:hover, h2 a.current:hover, h3 a.current:hover, h4 a.current:hover, h5 a.current:hover, h6 a.current:hover {
    color: inherit;
    padding: 0;
    margin: 0;
    display: block;
    font-size: inherit;
    line-height: inherit;
    text-decoration: inherit;
}


h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
    margin-top: 0;
}

a.current {
	// color: inherit;
	// cursor: default;
}

article, section, aside, nav, hgroup, footer, header {
    display: block;
}

hr {
	border-color:#ccc;
	border-style:solid;
	border-width:1px 0 0;
	clear:both;
	height:0;
	margin: 0;
}

p {
    margin: 0 0 1em;
}

html {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -moz-box-sizing: inherit;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
