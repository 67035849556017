.portfolio-content {
  $phone-category-buffer: 70vh;
  $tablet-plus-category-buffer: 70vh;

  .summary {
    padding-top: $rhythm * 7;
    padding-bottom: $rhythm * 1;
    @include scalable-font(32, 20, 1.1, null, 32);
    p {
      max-width: 18.9em;
    }
    @include tablet-plus {
      display: flex;
      .inner {
        width: calc(50% - #{tablet-plus-inset() / 2});
        margin: 0 0 0 auto;
      }
    }
  }
  .memberships {
    color: #ef4130;
    padding-bottom: $rhythm * 3;
    @include scalable-font(24, 13, 1.3, null, 24);
    @include tablet-plus {
      display: flex;
      .inner {
        width: calc(50% - #{tablet-plus-inset() / 2});
      }
    }
  }
  .page-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    .page-item {
      // text-transform: uppercase;
      .client {
        display: block;
      }
      .title {
        display: block;
      }
      .short-summary {
        margin-top: 2em;
        color: #8b8b8b;
      }
      .view-project {
        color: $black;
        display: inline-block;
      }
      .video-player {
        padding-top: 63.26781326781327%;
        position: relative;
        overflow: hidden;
        pointer-events: none;
        > * {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
      video {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      @include phone {
        // line-height: 0.8em;
        figcaption {
          opacity: 1;
          transition: 300ms opacity;
          padding-top: phone-inset();
          padding-bottom: phone-inset();
          .client {
            @include scalable-font(16, 12, 2, 0.013, 18);
          }
          .title {
            @include scalable-font(32, 18, 0.8, null, 32);
          }
          .short-summary {
            @include scalable-font(16, 12, 1.3, 0.013, 18);
          }
          .state-page-switch & {
            opacity: 0;
          }
        }
      }
      @include tablet-plus {
        figcaption {
          padding-top: tablet-plus-inset();
          padding-bottom: tablet-plus-inset() * 4;
          .client {
            @include scalable-font(18, 12, 2, 0.013, 18);
          }
          .title {
            @include scalable-font(32, 20, 0.8, null, 32);
          }
          .short-summary {
            max-width: 36em;
            @include scalable-font(18, 12, 1.3, 0.013, 18);
          }
        }
        .no-touchevents &[href] {
          // .view-project {
          //   opacity: 0;
          // }
          &:hover {
            .view-project {
              // opacity: 0.5;
            }
          }
        }
        //   figure {
        //     position: relative;
        //     overflow: hidden;
        //     > * {
        //       transition: 200ms transform;
        //       transform: translateZ(0) translateY(0);
        //       will-change: transform;
        //     }
        //     figcaption {
        //       z-index: 0;
        //       // position: absolute;
        //       // bottom: calc(-.7em + -#{tablet-plus-inset()});
        //       left: 1px;
        //     }
        //     .image {
        //       position: relative;
        //       z-index: 1;
        //     }
        //   }
        //   &:hover {
        //     figure > * {
        //       transition: 200ms transform 300ms;
        //       transform: translateZ(0) translateY(calc(-.8em + -#{tablet-plus-inset()}));
        //     }
        //   }
        // }
      }
    }
    // prevent jump from not hitting bottom
    &.last {
      .page-buffer {
        width: 100%;
        background: $white;
        padding-bottom: $phone-category-buffer;
      }
    }
    .page-footer {
      width: 100%;
      text-align: right;
      margin-top: $rhythm * 2;
      margin-bottom: $rhythm * 2;
      @include tablet-plus {
        @include font(30, .8);
      }
    }
  }
  .state-page-switch & .strip-above {
    display: none !important;
  }
  @include phone {
    // @.portfolio-content
    .page-items {
      .page-item {
        width: 100%;
        margin-bottom: phone-inset();
      }
    }
  }
  @include tablet-plus {
    // @.portfolio-content
    .page-items {
      margin-left: -#{tablet-plus-inset() * 0.5};
      margin-right: -#{tablet-plus-inset() * 0.5};
      .page-item {
        margin-bottom: tablet-plus-inset();
        margin-left: #{tablet-plus-inset() * 0.5};
        margin-right: #{tablet-plus-inset() * 0.5};
      }
      .page-item.display-100 {
        width: calc(100% - #{tablet-plus-inset()});
        // figcaption {
        //   text-align: center;
        //   max-width: 80%;
        //   margin-left: auto;
        //   margin-right: auto;
        //   .short-summary {
        //     margin-left: auto;
        //     margin-right: auto;
        //   }
        // }
      }
      .page-item.display-50 {
        width: calc(50% - #{tablet-plus-inset()});
      }
    }
  }
  @include fluid-plus {
    .page-items {
      max-width: calc(#{$fluid-max} + #{tablet-plus-inset()}) !important;
      margin-left: calc(50vw - #{tablet-plus-inset() * 0.5} - #{$fluid-max / 2}) !important;
    }
  }
  &:first-of-type {
    // .page-header {
    // DEBUG
    //   background: orange;
    //   opacity: .4;
    //   > * {
    //     opacity: .4;
    //   }
    //   @include phone {
    //     height: calc((1.6em + #{phone-inset() * 8})) !important;
    //   }
    //   @include tablet-plus {
    //     height: calc((1.6em + #{tablet-plus-inset() * 4})) !important;
    //   }
    // }
  }
  &:not(:first-of-type) {
    // incoming content
    .page-header.uncurrent-header {
      height: 0;
      display: none;
    }
    .page-header.current-header {
      display: block;
      @include phone {
        height: calc((#{$logo-height} + #{phone-inset() * 2})) !important;
      }
      @include tablet-plus {
        height: calc((#{$logo-height} + #{tablet-plus-inset() * 4})) !important;
      }
    }
    // .page-items {
    //   &.last {
    //     .page-buffer {
    //       display: none;
    //     }
    //   }
    // }
  }
}
