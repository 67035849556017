html {
  font-size: $base-font-size + 0px;
  line-height: $base-line-height;
  height: 100%;

  -webkit-tap-highlight-color: transparent;

  // @include fluid-plus {
  //   $increase-font-size: $base-font-size * 1.1428571429;
  //   font-size: $increase-font-size + 0px;
  // }
  &.fake-cursor {
    cursor: none !important;
    user-select: none;
    body,
    a,
    a:active,
    a:hover {
      cursor: none !important;
    }
  }
}

body {
  -webkit-font-smoothing: antialiased;
  font-variant-numeric: lining-nums;
  -moz-font-feature-settings: 'lnum';
  -webkit-font-feature-settings: 'lnum';
  font-feature-settings: 'lnum';
  font-family: 'helvetica-neue-web', Helvetica, sans-serif;
  font-weight: 400;
  background-color: $white;
  color: $almost-black;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: auto;
  &.state-page-switch {
    // HACK - super safari hack - prevent jump
    height: 1000000vh;
    a {
      pointer-events: none !important;
      cursor: none !important;
    }
  }
}

.cursor {
  width: 15px;
  height: 15px;
  background: $almost-black;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 50% 50%;
  display: none;
  pointer-events: none;
  transition: opacity 100ms, background-color 300ms;
  opacity: .9;
  // only show once we start moving it
  &[style*='top'] {
    display: block;
  }
  // .state-cursor-hovering & {
  //   background: $almost-black;
  //   opacity: 1;
  // }
  .state-cursor-exit & {
    opacity: 0;
  }
}

#content {
  > article {
    position: relative;
    z-index: 1;
    width: 100%;
    // transform: translateZ(0); // IMPORTANT don't do this until all internal items are not fixed
    // will-change: transform  // also this can't be applied either;
    @include page-transition;
    .container {
      @include grid-container(padding);
    }
    .page-header {
      position: sticky;
      top: 0;
      z-index: 99;
      width: 100%;
      height: $header-max-height;
      background: $white;
      @include scalable-font(32, 20, .8, null, 32);

      // background: orange;
      // opacity: .4;
      // > * {
      //   opacity: .4;
      // }

      hgroup { // (title / brand)
        // text-transform: uppercase;
        // @include fit-parent;
        z-index: 1;

        span {
          position: absolute;
          z-index: 301;
          transform: translateZ(0); // hardware accelerated
          will-change: position, top;
          background: $white;
          left: 0;
          right: 0;
          @include grid-container;
          // &.scroll-fixed {
          //   position: fixed;
          //   top: 0 !important;
          // }
          &.info {
            z-index: 302;
            svg {
              height: .8em;
              width: .8em;
              #Artboard {
                transition: 200ms stroke;
                stroke: $almost-black;
              }
            }
            a {
              color: $almost-black;
              transition: 200ms color;
              display: inline-block;
              .no-touchevents &:hover {
                color: $gray;
                svg #Artboard {
                  stroke: $gray;
                }
              }
            }
          }
          &.blank {
            background: none;
          }
          &.position-1 {
            z-index: 302;
            top: 0;
          }
          &.position-2 {}
          &.position-4 {
            text-align: right;
            background: none;
            margin-left: calc(50vw);
            max-width: $fluid-max / 2;
          }
          .toko-logo {
            height: $logo-height;
            width: auto;
            object-fit: contain;
          }
          > .text {
            display: block;
            position: relative;
            opacity: 0;
            &.state-incoming {
              transition: 300ms opacity $page-animation-ease 300ms;
              opacity: 1;
            }
            &.state-outgoing {
              position: absolute;
              left: 0;
              opacity: 0;
              transition: 300ms opacity $page-animation-ease;
            }
          }
        }
        @include phone {
          // @hgroup
          span {
            // padding-top: #{phone-inset() * 2};
            // padding-bottom: phone-inset();
            &.position-1 {
              padding-top: #{phone-inset() * 2};
            }
            .text {
              &.state-outgoing {
                padding-left: phone-inset();
              }
            }
            &.is-lower {
              padding-top: phone-inset();
              padding-bottom: phone-inset();
              // &:not(.scroll-fixed) {
              //   // bottom: 0;
              //   top: calc(#{$header-max-height} - (#{$logo-height} + #{phone-inset() * 2}));
              // }
            }
            &.position-4 {
              padding-top: calc(#{phone-inset()} + #{$logo-height - 0.9em});
            }
          }
        }
        @include tablet-plus {
          // @hgroup
          span {
            padding-top: tablet-plus-inset();
            padding-bottom: tablet-plus-inset();
            .text {
              &.state-outgoing {
                padding-left: tablet-plus-inset();
              }
            }
            &.is-lower {
              padding-top: tablet-plus-inset() * 2;
              padding-bottom: tablet-plus-inset() * 2;
              // &:not(.scroll-fixed) {
              //   // bottom: 0;
              //   top: calc(#{$header-max-height} - (#{$logo-height} + #{tablet-plus-inset() * 4}));
              // }
            }
            &.position-1,
            &.position-2 {
              left: -0.05em;
            }
            &.position-4 {
               right: -0.05em;
               padding-top: calc(#{tablet-plus-inset() * 2} + #{$logo-height - 0.8em});
             }
          }
        }
        // @include desktop-minus {
        //   // @hgroup
        //   @include scalable-font(45, 20, .8, 45);
        // }
        // @include fluid-plus {
        //   // @hgroup
        //   @include font(45, .8);
        // }
        @include desktop-plus {
          @media (max-height: 650px) {
            @include font(30, .8);
          }
        }
      }
      &.portfolio-item-header {
        z-index: 1;
        // $portfolio-item-header-max-height: 23vh;
        // height: $portfolio-item-header-max-height;
        hgroup { // (title / brand)
          span.position-1 {
            @include scalable-font(32, 15.5, 1.23, null, 32);
          }
        }
        @include phone {
          hgroup { // (title / brand)
            span.position-1 {
              @include font(20, 1.23);
            }
          }
        }
        @include tablet-plus {
          // @hgroup.portfolio-item-header
          span.position-1 {
            padding-top: #{tablet-plus-inset() * 3};
          }
        }
      }
    }
    // &:not(:first-of-type) {
    //   // incoming content
    // }
  }
}

#footer {
  position: fixed;
  z-index: 100;
  bottom: 0;
  left: 0;
  right: 0;
  background: $white;
  transform: translateZ(0); // hardware accelerated
  transition: #{$page-animation-speed / 8} transform #{$page-animation-speed / 4};
  padding-bottom: env(safe-area-inset-bottom);
  // TODO only hide this if going back
  //      current state below will need to be visible too (below)
  //      if first view - display?? ask tim
  nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    // text-transform: uppercase;
    opacity: 1;
    transition: 400ms opacity;
    @include grid-container;
    ul {
      li {
        display: inline-block;
        a {
          display: block;
          @include hover-opacity();
          .state-page-switch:not(.state-page-reset) &,
          .state-page-switch.state-page-portfolioToPortfolio &,
          .portfolio-item-page & {
            opacity: 0;
            transition: 400ms opacity;
            pointer-events: none;
          }
        }
      }
      &.categories {
        li {
          a {
            &.current,
            &.child-current {
              display: none;
            }
          }
        }
      }
      &.static {
        li {
          a {
            &:not(.current) {
              display: none;
            }
          }
        }
      }
    }
  }
  @include phone {
    // @#footer
    pointer-events: none;
    nav {
      margin-top: phone-inset();
      margin-bottom: #{phone-inset() * 2};
      a {
        margin-bottom: phone-inset();
        pointer-events: all;
      }
      ul.categories {
        li {
          a {
            height: 20px;
            padding-right: #{phone-inset() * 2};
            &.current,
            &.child-current {
              .portfolio-item-page:not(.state-page-switch) & {
                display: block;
                position: absolute;
                top: phone-inset();
                left: phone-inset();
                transition: none;
                opacity: 1;
                @include font(20, .8);
              }
            }
            .portfolio-item-page &,
            &.current,
            &.child-current {
              padding-right: 0;
            }
          }
        }
      }
      ul.static {
        li {
          a {
            span {
              padding-left: #{phone-inset() / 2};
            }
          }
        }
      }
    }
  }
  @include tablet-plus {
    // @#footer
    .portfolio-item-page & {
      transition: #{$page-animation-speed / 2} transform #{$page-animation-speed / 4};
      transform: translateY(101%) translateZ(0);
    }
    nav {
      margin-top: tablet-plus-inset();
      margin-bottom: tablet-plus-inset() * 2;
      // a {
      //   margin-bottom: 1.5vh;
      // }
      .categories {
        li > a > span {
          padding-right: #{tablet-plus-inset()};
        }
      }
      .static {
        li > a > span {
          padding-left: #{tablet-plus-inset()};
        }
      }
    }
  }
  @include tablet-plus {
    @include scalable-font(18, 12, .8, .013, 18);
  }
  @include desktop-plus {
    @media (max-height: 650px) {
      @include font(18, .8, .013);
    }
  }
}
