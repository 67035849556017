// All colours go here
$black: #000;
$almost-black: #2e2925;
$gray: #aaa;
$gray-light: #ccc;
$white: #fff;
$almost-white: #f3f2f3;


$red: #e74242;
$green: #60942e;

// Fonts
$base-font-size: 15;
$base-line-height: 1.4;

// Vertical Rhythm
$rhythm: $base-line-height + 0rem;

// Structural constants and helper mixins

$design-width: 1500; // reference width from the design
$design-gutter: 0;

// to add a max width for the site enable $fluid-max
$fluid-max: 2072px;
// $fluid-max: 1200px;
// $fluid-max: false;

$tablet-max: 1100px;
$tablet-portrait: 930px;
$phone-max: 667px;
$phone-portrait: 375px;

$total-cols: 24;
$single-column: percentage(1 / $total-cols);
$default-gutter: percentage($design-gutter / $design-width);
$fallback-width: 1200px; // used in place of 100vw where vw not supported

$page-animation-speed: 1000ms;
$page-animation-speed-half: 500ms;
// https://codepen.io/pen/?&editable=true
$page-animation-ease: cubic-bezier(.645, .045, .355, 1);

$header-max-height: 3.5em;
$logo-height: 2.5em;

@function phone-inset($negative: false) {
  @if ($negative == true) {
    @return -15px;
  } @else {
    @return 15px;
  }
}

@function tablet-plus-inset($negative: false) {
  @if ($negative == true) {
    // @return -1.5vw;
    @return -1vw;
  } @else {
    // @return 1.5vw;
    @return 1vw;
  }
}

@mixin grid-container($prop:padding) {
  @include phone {
    #{$prop}-left: phone-inset();
    #{$prop}-right: phone-inset();
  }
  @include tablet-plus {
    #{$prop}-left: tablet-plus-inset();
    #{$prop}-right: tablet-plus-inset();
  }
  @include desktop-plus {
    #{$prop}-left: 5%;
    #{$prop}-right: 5%;
  }

  @if ($fluid-max) {
    @include fluid-plus {
      max-width: $fluid-max;
      // min-width: $min-fluid-width - phone-inset() * 2;
      // margin: 0 auto;
      margin-left: calc(50vw - #{$fluid-max / 2});
    }
  }
}

@mixin gutters($gutter: $default-gutter, $parent-cols: $total-cols) {
  // Add left/right margin according to the grid gutter width.
  @if ($default-gutter != 0) {
    $factor: $total-cols / $parent-cols;
    margin-left: $gutter * $factor;
    margin-right: $gutter * $factor;
  }
}

@function columns($cols, $parent-cols: $total-cols, $gutter: $default-gutter) {
  $factor: $total-cols / $parent-cols;
  @return ($cols * $single-column - $gutter * 2) * $factor;
}

@mixin cols($cols, $parent-cols: $total-cols, $gutter: $default-gutter) {
  // Set width to the specified number of cols, with gutters.
  width: columns($cols, $parent-cols, $gutter);
  @include gutters($gutter, $parent-cols);
}

// @mixin ib-grid($args...) {
//   // wrapper for cols() which also makes the element an inline-block column
//   display: inline-block;
//   vertical-align: top;
//   @if length($args) > 0 {
//     @include cols($args...);
//   }
// }

@mixin prefix($cols: 0, $parent-cols: $total-cols, $gutter: $default-gutter,
              $prop: margin-left) {
  // Add left margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}: (($single-column * $cols) + $gutter) * $factor;
}

@mixin suffix($cols: 0, $parent-cols: $total-cols, $gutter: $default-gutter,
              $prop: margin-right) {
  // Add right margin of the specified number of cols
  $factor: $total-cols / $parent-cols;
  #{$prop}: (($single-column * $cols) + $gutter) * $factor;
}

@function grid-px($cols, $gutter: $default-gutter) {
  // Calculate the pixel width of a fluid grid element, at $design-width
  @return round(($cols / $total-cols - 2 * $gutter / 100%) * $design-width);
}

// media
@mixin fluid-plus {
  @if ($fluid-max) {
    $min: $fluid-max + 1;
    @media screen and (min-width: $min) {
      @content;
    }
  }
}
@mixin desktop {
  $min: $tablet-max + 1;
  @if ($fluid-max) { $max: $fluid-max; }
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin desktop-plus {
  $min: $tablet-max + 1;
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin desktop-minus {
  @if ($fluid-max) {
    $max: $fluid-max;
    @media screen and (max-width: $max) {
      @content;
    }
  }
}
// mobile: encompassing tablet & phone
@mixin tablet-minus {
  $max: $tablet-max;
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin tablet {
  $min: $phone-max + 1;
  $max: $tablet-max;
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin tablet-plus {
  $min: $phone-max + 1;
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin tablet-landscape-plus {
  $min: $tablet-portrait + 1;
  @media screen and (min-width: $min) {
    @content;
  }
}
@mixin tablet-landscape {
  $min: $tablet-portrait + 1;
  $max: $tablet-max;
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin tablet-portrait {
  $min: $phone-max + 1;
  $max: $tablet-portrait;
  @media screen and (min-width: $min) and (max-width: $max) {
    @content;
  }
}
@mixin tablet-portrait-minus {
  $max: $tablet-portrait;
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin phone {
  $max: $phone-max;
  @media screen and (max-width: $max) {
    @content;
  }
}
@mixin phone-portrait {
  $max: $phone-portrait;
  @media screen and (max-width: $max) {
    @content;
  }
}
