
.portfolio-item-content {
  // body:not(.state-page-switch) & .overlay {
  .overlay {
    @include fit-parent;
    z-index: 1;
    // cursor: none;
    // background: rgba(pink, .2);
  }
  .item {
    position: relative;
  }
  .item + .item {
    .page-header {
      // display: none;
    }
    .blocks {
      &::before {
        @include phone {
          top: calc(50vh);
        }
        @include tablet-plus {
          top: calc(50vh);
        }
      }
      [data-track-visibility]:first-of-type {
        @include phone {
          padding-top: 50vh;
        }
        @include tablet-plus {
          padding-top: 50vh;
        }
      }
    }
    &:last-of-type {
      .description {
        padding-bottom: 20vh;
      }
    }
  }

  .blocks {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    pointer-events: none;
    &::before {
      position: absolute;
      z-index: 1;
      content: '';
      background: $white;
    }
    a,
    button,
    .video-player video:not([data-autoplay]),
    .video-player .poster {
      pointer-events: all;
    }
    > * {
      width: 100%;
      position: relative;
      z-index: 2;
    }
    figure {
      figcaption {
        // text-transform: uppercase;
        // line-height: .8em;
        > * {
          margin: 0;
          display: inline;
        }
        a {
          @include hover-underline;
        }
      }
    }
    .description {
      .client,
      .date {
        text-transform: uppercase;
        @include scalable-font(35, 15.5, 1.23, null, 35);
      }
      .date {
        letter-spacing: .02;
      }
      .specifications,
      .summary {
        a {
          @include hover-underline;
        }
      }
      .specifications {
        // @include font(22, 1.45);
        @include scalable-font(24, 13, 1.3, null, 24);
      }
      .summary {
        // @include font(24, 1.3);
        @include scalable-font(24, 13, 1.3, null, 24);
      }
    }
    @include phone {
      // @.blocks
      &::before {
        top: 0;
        left: 0;
        right: 0;
        bottom: phone-inset() * 2;
      }
      > * {
        order: 1;
        padding-bottom: phone-inset() * 2;
      }
      figure {
        figcaption {
          padding-top: phone-inset();
          @include scalable-font(22, 12, 1.4, null, 22);
        }
      }
      .description {
        padding-top: $rhythm;
        order: 2;
        // padding-top: $rhythm;
         > * {
          padding-bottom: $rhythm;
        }
        .date,
        .client {
          @include font(20, 1.23);
        }
        .client {
          padding: 0;
        }
      }
    }
    @include tablet-plus {
      // @.blocks
      &::before {
        top: 0;
        // left: tablet-plus-inset($negative: true);
        // right: tablet-plus-inset($negative: true);
        left: 0;
        right: 0;
        bottom: tablet-plus-inset() * 2;
      }
      > * {
        &:not(:last-of-type) {
          padding-bottom: tablet-plus-inset() * 2;
        }
        &.display-50 {
          width: calc(50% - #{tablet-plus-inset() / 2});
          &.left {
            margin-right: #{tablet-plus-inset() / 2};
          }
          &.right {
            margin-left: #{tablet-plus-inset() / 2};
          }
        }
      }
      figure {
        figcaption {
          padding-top: tablet-plus-inset();
          // white-space: nowrap;
        }
      }
      .description {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-bottom: 15vh;
        > * {
          padding-right: $rhythm;
        }
        .client,
        .date {
          width: 50%;
          padding-top: tablet-plus-inset();
        }
        .specifications {
          width: 35%;
          margin-left: 15%;
          padding-top: 15vh;
        }
        .summary {
          width: 50%;
          padding-top: 15vh;
          > * {
            max-width: 25em;
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
        .date,
        .summary {
          padding-left: tablet-plus-inset() * .5;
        }
      }
    }
    @include desktop-plus {
      // @.blocks
      .description {
        .specifications {
          width: 25%;
          margin-left: 25%;
          padding-top: 15vh;
        }
      }
    }
  }
  .next-item {
    display: block;
  }
  @include phone {
    min-height: 65vh; // minus header (40%) and then an extra 5% so it stay's scrolling
  }
}
